






import Vue from 'vue';

export default Vue.extend({
  name: 'Main',
  props: {
    section: String,
  },
  data() {
    return {
      currentSection: '',
    };
  },
  mounted() {
    // console.log(this);
  },
});
