import { ContentStructure, Post } from '@/types/interface';

export const contentStructure: ContentStructure = {
  categories: [
    {
      name: 'Software Architecture',
      topics: [
        'Monolithic',
        'Microservices',
        'Event Driven Architecture',
        'Load Balancing',
      ],
      posts: [
        {
          name: 'Breaking Down Applications by Business Capabilities',
          img: {
            name: 'Breaking_Down_Applications_by_Business_Capabilities',
            basePath: '@/assets/img/',
            ext: '.jpeg',
          },
          tags: ['Business-Capability', 'Microservices', 'Scalable-Systems'],
          date: '14/11/24',
          read: '8 mins read',
        },
        {
          name: '8 Load Balancing Algorithms for Modern Systems',
          img: {
            name: '8_Load_Balancing_Algorithms_for_Modern_Systems',
            basePath: '@/assets/img/',
            ext: '.jpeg',
          },
          tags: ['Load-Balancing', 'Traffic-Distribution', 'High-Availability'],
          date: '07/11/24',
          read: '5 mins read',
        },
        {
          name: 'Event-Driven Architecture for Scalable Systems',
          img: {
            name: 'Event-Driven_Architecture_for_Scalable_Systems',
            basePath: '@/assets/img/',
            ext: '.jpeg',
          },
          tags: ['Event-Driven-Architecture', 'Real-Time-Processing'],
          date: '04/11/24',
          read: '6 mins read',
        },
        {
          name: 'Monolithic vs. Microservices Architectures',
          img: {
            name: 'Monolithic_vs_Microservices_Architectures',
            basePath: '@/assets/img/',
            ext: '.jpeg',
          },
          tags: [
            'Monolithic-Architecture',
            'Microservices',
            'Technology-Trends',
          ],
          date: '02/11/24',
          read: '4 mins read',
        },
      ],
    },
    {
      name: 'Algorithms',
      topics: [
        'Big O Notation',
        'Algorithm Essentials',
        'Time Complexity',
        'Space Complexity',
        'Sorting Algorithms',
      ],
      posts: [
        {
          name: 'Exploring Sorting Algorithms in JavaScript',
          img: {
            name: 'Exploring_Sorting_Algorithms_in_JavaScript',
            basePath: '@/assets/img/',
            ext: '.jpeg',
          },
          tags: [
            'Bubble-Sort',
            'Insertion-Sort',
            'Quick-Sort',
            'Selection-Sort',
          ],
          date: '28/10/24',
          read: '7 mins read',
        },
        {
          name: 'Introduction to Algorithms',
          img: {
            name: 'Introduction_to_Algorithms',
            basePath: '@/assets/img/',
            ext: '.jpeg',
          },
          tags: ['Big-O-Notation', 'Time-Complexity', 'Space-Complexity'],
          date: '26/10/24',
          read: '6 mins read',
        },
        {
          name: 'The Power of Efficiency: Big O Analysis',
          img: {
            name: 'The_Power_of_Efficiency_Big_O_Analysis',
            basePath: '@/assets/img/',
            ext: '.jpeg',
          },
          tags: ['Big-O-Notation', 'Algorithm-Efficiency'],
          date: '19/06/23',
          read: '4 mins read',
        },
      ] as Post[],
    },
    {
      name: 'Engineering Managment',
      topics: [
        'Engineering Leadership',
        'Team Leadership',
        'Agile Practices',
        'Scrum Methodology',
      ],
      posts: [
        {
          name: 'Agile & Scrum Practices',
          img: {
            name: 'Agile_&_Scrum_Practices',
            basePath: '@/assets/img/',
            ext: '.jpeg',
          },
          tags: ['Agile-Practices', 'Scrum-Methodology', 'Team-Performance'],
          date: '23/10/24',
          read: '8 mins read',
        },
        {
          name: 'Leadership and Team Management for Engineering',
          img: {
            name: 'Leadership_and_Team_Management_for_Engineering',
            basePath: '@/assets/img/',
            ext: '.jpeg',
          },
          tags: ['Technical-Leadership', 'Team-Leadership'],
          date: '14/10/24',
          read: '6 mins read',
        },
        {
          name: 'Introduction to Software Engineering Management',
          img: {
            name: 'Introduction_to_Software_Engineering_Management',
            basePath: '@/assets/img/',
            ext: '.jpeg',
          },
          tags: ['Technical-Leadership', 'Team-Leadership'],
          date: '13/10/24',
          read: '3 mins read',
        },
        {
          name: 'Unlocking the Potential of Engineering Teams',
          img: {
            name: 'Unlocking_the_Potential_of_Engineering_Teams',
            basePath: '@/assets/img/',
            ext: '.jpeg',
          },
          tags: ['Engineering-Leadership', 'Team-Empowerment'],
          date: '15/06/23',
          read: '3 mins read',
        },
      ] as Post[],
    },
    {
      name: 'Web Fundamentals',
      topics: [
        'Lighthouse',
        'Service Worker',
        'PWA',
        'GraphQL',
        'Contentful',
        'HTML',
        'CSS',
        'Layouts',
      ],
      posts: [
        {
          name: 'Exploring Well Known CSS Layouts',
          img: {
            name: 'Exploring_Well_Known_CSS_Layouts',
            basePath: '@/assets/img/',
            ext: '.jpeg',
          },
          tags: ['HTML', 'CSS', 'Layouts', 'Flexbox', 'Grid'],
          date: '19/10/24',
          read: '4 mins read',
        },
        {
          name: 'HTML and Semantics: Building Meaningful Web Content',
          img: {
            name: 'HTML_and_Semantics_Building_Meaningful_Web_Content',
            basePath: '@/assets/img/',
            ext: '.jpeg',
          },
          tags: ['HTML', 'Accessibility', 'SEO', 'Web-Development'],
          date: '18/10/24',
          read: '4 mins read',
        },
        {
          name: 'Check your Web App health with Lighthouse',
          img: {
            name: 'Check_your_Web_App_health_with_Lighthouse',
            basePath: '@/assets/img/',
            ext: '.jpeg',
          },
          tags: ['Core-Web-Vitals', 'Web-Performance'],
          date: '26/12/21',
          read: '3 mins read',
        },
        {
          name: 'Service worker caching strategies',
          img: {
            name: 'Service_worker_caching_strategies',
            basePath: '@/assets/img/',
            ext: '.jpeg',
          },
          tags: ['Service-Worker-Caching', 'Workbox'],
          date: '06/07/21',
          read: '4 mins read',
        },
        {
          name: 'Progressive Web Applications in 2021',
          img: {
            name: 'Progressive_Web_Applications_in_2021',
            basePath: '@/assets/img/',
            ext: '.jpeg',
          },
          tags: ['PWA', 'Service-Worker'],
          date: '22/06/21',
          read: '4 mins read',
        },
        {
          name: 'Wrap any rest API with GraphQL from the frontend',
          img: {
            name: 'Wrap_any_rest_API_with_GraphQL_from_the_frontend',
            basePath: '@/assets/img/',
            ext: '.jpeg',
          },
          tags: ['GraphQL', 'Apollo-Client', 'API-Optimization'],
          date: '26/01/21',
          read: '3 mins read',
        },
        {
          name: 'Save money and time with a headless CMS',
          img: {
            name: 'Save_money_and_time_with_a_headless_CMS',
            basePath: '@/assets/img/',
            ext: '.jpeg',
          },
          tags: ['Contentful', 'typescript', 'Vue', 'Headless-CMS'],
          date: '17/01/21',
          read: '4 mins read',
        },
      ] as Post[],
    },
  ],
};
