// vue babel plugin doesn't support pragma replacement
import { mdx } from '@mdx-js/vue'
let h;
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`An experienced technology leader with a passion for innovation and results.`}</p>
    <p>{`I've successfully led teams to deliver exceptional digital experiences by fostering a collaborative
and high-performing culture.`}</p>
    <p>{`With a deep understanding of UI/UX, Web performance, and Software Engineering, I excel at identifying
and solving complex technical challenges, ensuring projects are always on track and meet the highest standards.`}</p>
    <p>{`I am committed to staying up-to-date with the latest technologies and industry trends to drive continuous improvement
and deliver innovative solutions.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;

export default {
  name: 'Mdx',
  inject: {
    $mdxComponents: {
      default: () => () => ({})
    }
  },
  computed: {
    components() {
      return this.$mdxComponents()
    }
  },
  render(createElement) {
    h = mdx.bind({ createElement, components: this.components })
    return MDXContent({ components: this.components })
  }
}
   