




























import Vue from 'vue';

export default Vue.extend({
  name: 'Header',
  data() {
    return {
      isDarkTheme: false,
      clickSound: new Audio(require('@/assets/click-sound.mp3')),
    };
  },
  mounted() {
    const savedTheme = localStorage.getItem('theme');
    this.isDarkTheme = savedTheme
      ? savedTheme === 'dark'
      : window.matchMedia('(prefers-color-scheme: dark)').matches;
    this.applyTheme();
  },
  methods: {
    getBulbSvg(isDark: boolean) {
      const strokeColor = isDark ? '#ffffff' : '#000000';
      const fillColor = isDark ? 'none' : '#fbff00';

      return `
        <svg width="25px" height="25px" viewBox="0 0 24.00 24.00" 
        fill="${fillColor}" xmlns="http://www.w3.org/2000/svg" 
        stroke="${strokeColor}">
          <g id="SVGRepo_bgCarrier" stroke-width="0" transform="${
            isDark
              ? 'translate(6.96,6.96), scale(0.42)'
              : 'translate(6.36,6.36), scale(0.47)'
          }"></g>
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke-width="${
            isDark ? '0.288' : ''
          }"></g>
          <g id="SVGRepo_iconCarrier">
            <path d="M10 17.6586V20C10 21.1046 10.8954 22 12 22C13.1046 22 14 21.1046 14 20V17.6586M12 2V3M3 12H2M5.5 5.5L4.8999 4.8999M18.5 5.5L19.1002 4.8999M22 12H21M18 12C18 15.3137 15.3137 18 12 18C8.68629 18 6 15.3137 6 12C6 8.68629 8.68629 6 12 6C15.3137 6 18 8.68629 18 12Z" stroke="${strokeColor}" stroke-width="${
        isDark ? '1.848' : '1.752'
      }" stroke-linecap="round" stroke-linejoin="round"></path>
          </g>
        </svg>
      `;
    },
    handleToggleTheme() {
      this.clickSound.play();
      this.toggleTheme();
    },
    toggleTheme() {
      this.isDarkTheme = !this.isDarkTheme;
      localStorage.setItem('theme', this.isDarkTheme ? 'dark' : 'light');
      this.applyTheme();
    },
    applyTheme() {
      if (this.isDarkTheme) {
        document.documentElement.classList.add('dark-theme');
      } else {
        document.documentElement.classList.remove('dark-theme');
      }
    },
  },
});
