


























import Vue, { Component } from 'vue';
import AboutMe from '../content/AboutMe.mdx';
import Hobbies from '../content/Hobbies.mdx';
import { contentStructure } from '@/content/contentStructure';

export default Vue.extend({
  name: 'Home' as string,
  components: {
    AboutMe: AboutMe as Component,
    Hobbies: Hobbies as Component,
  },
  data() {
    return {
      latestPosts: [] as any,
    };
  },
  async created() {
    this.getLatestPost();
  },
  methods: {
    getLatestPost() {
      const posts: { name: string; imgName: string; date: string }[] = [];
      const categories = contentStructure.categories;

      for (const category of categories) {
        for (const post of category.posts) {
          const {
            name,
            img: { name: imgName },
            date,
          } = post;
          posts.push({ name, imgName, date });
        }
      }

      this.latestPosts = posts
        .sort((a, b) => {
          return (
            this.parseDate(b.date).getTime() - this.parseDate(a.date).getTime()
          );
        })
        .slice(0, 10); // Limit to the latest 10 posts
    },

    // Helper function to parse date strings
    parseDate(dateString: string): Date {
      const [day, month, year] = dateString.split('/').map(Number);
      return new Date(year, month - 1, day);
    },
  },
});
