// src/directives/highlight.js
import Vue from 'vue';
import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
import css from 'highlight.js/lib/languages/css';
import xml from 'highlight.js/lib/languages/xml';
import 'highlight.js/styles/github-dark-dimmed.css';

hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('css', css);
hljs.registerLanguage('xml', xml);

Vue.directive('highlightjs', {
  deep: true,
  bind(el) {
    // On first bind, highlight all targets
    const blocks = el.querySelectorAll('pre code');
    blocks.forEach(block => {
      hljs.highlightElement(block);
    });
  },
  // componentUpdated(el) {
  //   // After each DOM update, re-highlight all blocks
  //   const blocks = el.querySelectorAll('pre code');
  //   blocks.forEach(block => {
  //     hljs.highlightElement(block);
  //   });
  // },
});
