// vue babel plugin doesn't support pragma replacement
import { mdx } from '@mdx-js/vue'
let h;
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I enjoy spending quality time with my sons, often engaging in exciting video game adventures together.`}</p>
    <p>{`I stay updated with the latest tech trends by reading my Twitter and Dev.to feeds whenever
I have a few moments to spare.`}</p>
    <p>{`I'm passionate about exploring new ideas and concepts through hands-on experimentation.
This often leads me to dive into various sample projects inspired by my feeds. Additionally,
I have a keen interest in drones and love capturing stunning photographs of the world around me.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;

export default {
  name: 'Mdx',
  inject: {
    $mdxComponents: {
      default: () => () => ({})
    }
  },
  computed: {
    components() {
      return this.$mdxComponents()
    }
  },
  render(createElement) {
    h = mdx.bind({ createElement, components: this.components })
    return MDXContent({ components: this.components })
  }
}
   