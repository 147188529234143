import Vue from 'vue';

// Define the type for the Service Worker Registration
interface ServiceWorkerRegistrationWithWaiting
  extends ServiceWorkerRegistration {
  waiting: ServiceWorker | null; // Use `null` instead of `undefined`
}

export default Vue.extend({
  data() {
    return {
      refreshing: false as boolean, // Initial type
      registration: null as ServiceWorkerRegistrationWithWaiting | null, // Nullable registration
      updateExists: false as boolean, // Tracks if update is available
    };
  },

  created() {
    // Listen for the custom 'swUpdated' event
    document.addEventListener(
      'swUpdated',
      this.updateAvailable as EventListener,
      {
        once: true,
      },
    );

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return;
      this.refreshing = true;
      // Reload the page when the service worker takes control
      window.location.reload();
    });
  },

  methods: {
    // Handle the custom event when the SW is updated
    updateAvailable(event: CustomEvent) {
      this.registration = event.detail as ServiceWorkerRegistrationWithWaiting;
      this.updateExists = true;
    },

    // Refresh the app by sending the 'SKIP_WAITING' message to the SW
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) return;
      // Send the 'SKIP_WAITING' message to the SW
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    },
  },
});
